<script setup lang="ts">
// @ts-expect-error: No 3rd party library types
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";

const props = defineProps<{ defaultOpen?: boolean; show: { toggle: boolean } }>();
const emit = defineEmits(["toggle"]);

const isOpen = ref(props.defaultOpen ?? false);
</script>

<template>
  <div v-bind="$attrs">
    <div class="flex cursor-pointer items-center justify-between" @click="isOpen = !isOpen; emit('toggle', isOpen);">
      <slot name="title" />
      <i
        v-if="show.toggle"
        class="pe-lg transition-transform duration-[400ms]"
        :class="[isOpen ? 'pe-7s-angle-down rotate-180' : 'pe-7s-angle-down rotate-0']"
      />
    </div>
    <CollapseTransition :duration="400">
      <div v-show="isOpen">
        <slot name="content" />
      </div>
    </CollapseTransition>
  </div>
</template>

<style scoped>

</style>
